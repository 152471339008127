import styled, { css } from "styled-components";
import { colors } from "../../tokens/colors";

export const Input = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid ${colors.lightGray};
  border-radius: 4px;
  margin-bottom: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${colors.darkBlue};
    outline: none;
  }

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${colors.errorText};
    `}
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid ${colors.lightGray};
  border-radius: 4px;
  margin-bottom: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${colors.darkBlue};
    outline: none;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid ${colors.lightGray};
  border-radius: 4px;
  margin-bottom: 16px;

  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${colors.errorText};
    `}
`;

export const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 8px;
  color: ${colors.darkGray};
`;
