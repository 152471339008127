import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import Cookies from "js-cookie";
import ApiStatus from "../Farsi/Shared/Constants/apiStatus";

const initialState = {
  category: "",
  kommun: "",
  county: "",
  mobile: "",
  title: "",
  details: "",
  images: [],
  id: 0,
  price: "",

  apiStatus: {
    createBazarAd: ApiStatus.ApiIdle,
    editBazarAd: ApiStatus.ApiIdle,
  },
};

export const createBazarAd = createAsyncThunk(
  "createBazarAd",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState().RegisteringBazarAds;

      const token = Cookies.get("jwt-token");

      const res = await Axios.post(
        "https://app.nordicbazar.com/api/v1/ad/create_ad",
        {
          title: state.title,
          category: state.category,
          county: state.county,
          description: state.details,
          municipality: state.kommun,
          price: state.price,
          image_url: state.images,
          source: "website",
          consent: {
            necessary: true,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const editBazarAd = createAsyncThunk(
  "editBazarAd",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState().RegisteringBazarAds;

      const token = Cookies.get("jwt-token");

      const res = await Axios.put(
        `https://app.nordicbazar.com/api/v1/ad/update_ad/${state.id}`,
        {
          title: state.title,
          category: state.category,
          county: state.county,
          description: state.details,
          municipality: state.kommun,
          price: state.price,
          image_url: state.images,
          source: "website",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const registerAdsSlice = createSlice({
  name: "registerAds",
  initialState,
  reducers: {
    setAdsCategory(state, action) {
      state.category = action.payload;
    },
    setAdsPrice(state, action) {
      state.price = action.payload;
    },
    setAdsCity(state, action) {
      state.kommun = action.payload;
    },
    setAdsCounty(state, action) {
      state.county = action.payload;
    },
    setAdsMobile(state, action) {
      state.mobile = action.payload;
    },
    setAdsTitle(state, action) {
      state.title = action.payload;
    },
    setAdsDetails(state, action) {
      state.details = action.payload;
    },
    setAdsId(state, action) {
      state.id = action.payload;
    },
    setAdsImages(state, action) {
      state.images = action.payload;
    },

    resetStateRegisteringBazarAd() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBazarAd.pending, (state) => {
        state.apiStatus.createBazarAd = ApiStatus.ApiStarting;
      })
      .addCase(createBazarAd.fulfilled, (state) => {
        state.apiStatus.createBazarAd = ApiStatus.ApiDone;
      })
      .addCase(createBazarAd.rejected, (state) => {
        state.apiStatus.createBazarAd = ApiStatus.ApiFailed;
      });
    builder
      .addCase(editBazarAd.pending, (state) => {
        state.apiStatus.editBazarAd = ApiStatus.ApiStarting;
      })
      .addCase(editBazarAd.fulfilled, (state, action) => {
        state.selectedAd = action.payload;
        state.apiStatus.editBazarAd = ApiStatus.ApiDone;
      })
      .addCase(editBazarAd.rejected, (state, action) => {
        state.error = action.payload;
        state.apiStatus.editBazarAd = ApiStatus.ApiFailed;
      });
  },
});

export const {
  setAdsCategory,
  setAdsPrice,
  setAdsCity,
  setAdsCounty,
  setAdsMobile,
  setAdsTitle,
  setAdsDetails,
  setAdsId,
  setAdsImages,
  resetStateRegisteringBazarAd,
} = registerAdsSlice.actions;

export default registerAdsSlice.reducer;
