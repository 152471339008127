export const colors = {
  darkBlue: "#005293", //Priamry
  darkYellow: "#FECB00", //Secondary
  lightBlue: "#E3F3FF",
  lightYellow: "#FFF5CF",
  lightGray: "#EBEBEB",
  darkGray: "#9e9e9e",
  extraLightGray: "#faf7f7",
  doubleExtraLightGray: "#efefef",
  hoverlightBlue: "#218838",
  hoverLightBlue: "#2874CC",
  hoverDarkBlue: "#004080",
  white: "#fff",
  black: "#444444",
  errorText: "#c90011",
  errorHover: "#a5000d",
  mainYellow: "#f0c040",
};
