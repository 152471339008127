export const fonts = {
  primary: "IRANYekan, sans-serif, Tahoma",
  secondary: '"DIN Next LT Arabic Regular, Arial, sans-serif"',
  fallback: '"Amiri, serif"',
};

export const fontFaces = `
    @font-face {
      font-family: 'IRANYekan';
      src: url('assets/fonts/iranyekan/IRANYekanMedium.ttf');
      font-weight: normal;
      font-style: normal;
    }
    @font-face {
      font-family: 'DIN Next LT Arabic Regular';
      src: url('./fonts/699a5dddcff7f1cbb08d964cc275cb50ce7020291901931.ttf') format('truetype');
    }
  `;
