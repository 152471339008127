import { fonts } from './fonts';

export const typography = {
  headerFont: fonts.primary, 
  bodyFont: fonts.primary,   
  headerSize: '2rem',        // (h1)
  subheaderSize: '1.5rem',   // (h2)
  bodySize: '1rem',          
  headerWeight: 700,         
  bodyWeight: 400,          
  lineHeight: 1.5,           
};
