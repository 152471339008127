import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store/configStore";
import Modal from "react-modal";
import { GlobalStyle } from "./design-system/styles/GlobalStyle";
import * as Sentry from "@sentry/react";
import { hotjar } from "react-hotjar";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga4";

Modal.setAppElement("#root");

// Initialize Analytics globally
ReactGA.initialize("G-0H9SY748X4");
ReactGA.send("pageview");
// Initialize Hotjar globally
hotjar.initialize(5226624, 6);
// Initialize Sentry
Sentry.init({
  dsn: "https://ed7b865046f06a7ce447765096cbc2f8@o4507980054855680.ingest.de.sentry.io/4507980056690768",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Router>
        <GlobalStyle />
        <App />
      </Router>
    </Sentry.ErrorBoundary>
  </Provider>
);

serviceWorker.unregister();
