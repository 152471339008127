import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  environment: "",
};

const sharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    setEnvironment(state, action) {
      state.environment = action.payload;
    },
  },
});

export const { setEnvironment } = sharedSlice.actions;

export default sharedSlice.reducer;
