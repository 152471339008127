
import styled from 'styled-components';
import { colors } from '../../tokens/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
`;

export const InnerContainer = styled.div`
  width: 50%;
  background-color: #ffffff; 
  padding: 3rem;
  margin: 0 auto; /* Center the form container */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 70%; 
  }
`;

export const Card = styled.div`
  background-color: ${colors.white};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
