import firebase from "firebase/app";
import "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBwNtKfz2RSyZRxF6DvQ7CIBhs0M2Ie16k",
  authDomain: "nordicbazar-76aa7.firebaseapp.com",
  databaseURL: "https://nordicbazar-76aa7.firebaseio.com",
  projectId: "nordicbazar-76aa7",
  storageBucket: "nordicbazar-76aa7.appspot.com",
  messagingSenderId: "225123526728",
  appId: "1:225123526728:web:02449d4b6ceaf01f",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = firebase.storage(app);

// Export the storage for use in other parts of the app
export { storage, firebase as default };
