import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import BazarServiceIcon from "../../../assets/icons/BazarServiceIcon.svg";
import BusinessServiceIcon from "../../../assets/icons/BusinessServiceIcon.svg";
import HomeServiceIcon from "../../../assets/icons/HomeServiceIcon.svg";
import PersonIcon from "@mui/icons-material/Person";
import { colors } from "../../../design-system";

const BottomBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  background-color: white;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 1000;
  width: 100%;
  @media (min-width: 769px) {
    display: none;
  }
`;

const BarButton = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
  color: ${(props) => (props.active ? colors.darkBlue : colors.black)};
  text-decoration: none;

  &:hover {
    color: #000;
  }

  img {
    margin-bottom: 5px;
    width: 24px;
    height: 24px;
    filter: ${(props) =>
      props.active
        ? "invert(27%) sepia(53%) saturate(2715%) hue-rotate(180deg) brightness(97%) contrast(101%)"
        : "brightness(0) saturate(100%)"};
  }
`;

const StyledIcon = styled(PersonIcon)`
  margin-bottom: 5px;
  width: 24px;
  height: 24px;
  filter: ${(props) =>
    props.active ? "none" : "brightness(0) saturate(100%)"};
  color: ${(props) => (props.active ? colors.darkBlue : colors.black)};
`;

const BarItemText = styled.span`
  color: ${(props) => (props.active ? colors.darkBlue : colors.black)};
`;

function BottomBar() {
  const location = useLocation();

  const menuItems = [
    {
      to: "/find-home",
      activePaths: ["/find-home", "/register-home"],
      icon: HomeServiceIcon,
      label: "اجاره خانه",
    },
    {
      to: "/bazar",
      activePaths: ["/bazar", "/regAds-sweden-fa"],
      icon: BazarServiceIcon,
      label: "بازار",
    },
    {
      to: "/companies",
      activePaths: ["/companies", "/register-company"],
      icon: BusinessServiceIcon,
      label: "کسب‌و‌کار",
    },
    {
      to: "/user-panel",
      activePaths: ["/user-panel"],
      icon: <StyledIcon active={location.pathname === "/user-panel"} />,
      label: "پروفایل",
    },
  ];

  return (
    <BottomBarContainer>
      {menuItems.map((item, index) => {
        const isActive = item.activePaths.includes(location.pathname);
        return (
          <BarButton key={index} to={item.to} active={isActive}>
            {typeof item.icon === "string" ? (
              <img src={item.icon} alt={item.label} />
            ) : (
              item.icon
            )}
            <BarItemText active={isActive}>{item.label}</BarItemText>
          </BarButton>
        );
      })}
    </BottomBarContainer>
  );
}

export default BottomBar;
