const constVars = {
  BusinessEnv: "خدمات",
  BazarEnv: "بازار",
  HomeEnv: "خانه",
  BusinessEditingEnv: "ویرایش خدمات",
  HomeEditingEnv: "ویرایش خانه",
  HomeCreateAdEnv: "ثبت خانه",
  AdminBusinessEditingEnv: "ویرایش خدمات ادمین",
  AdminHomeEditingEnv: "ویرایش خانه ادمین",
  AdminBazarEditingEnv: "ویرایش بازار ادمین",
  BazarEditingEnv: "ویرایش بازار",
};

export default constVars;
