import React, { useEffect, Suspense, lazy } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import "./App.css";
import { getUser, setIsUserLoggedIn } from "./store/userPanelSlice";
import { setAdminLoggedIn } from "./store/adminSlice";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import styled from "styled-components";
import BottomBar from "./Farsi/Shared/BottomBarMobile/BottomBarMobile";
import AppRoutes from "./AppRoutes";

const TopContainer = lazy(() => import("./Farsi/Shared/Top/topContainer"));
const CookieConsent = lazy(() => import("react-cookie-consent"));

const TopContainerWrapper = styled.div`
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
`;

const AppWrapper = styled.div`
  position: relative;
  margin-top: 5px;
  @media (max-width: 768px) {
    margin-top: 60px;
    margin-bottom: 80px;
  }
`;

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location && typeof window.hj === "function") {
      window.hj("stateChange", location.pathname);
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [location]);

  useEffect(() => {
    if (!Cookies.get("jwt-token")) {
      dispatch(setIsUserLoggedIn(false));
    } else {
      dispatch(getUser());
    }
    if (!Cookies.get("jwt-token-admin")) {
      dispatch(setAdminLoggedIn(false));
    }
  }, [dispatch]);

  return (
    <div className="App">
      <Suspense fallback={<div>Loading...</div>}>
        <CookieConsent
          location="bottom"
          buttonText="Agree!"
          cookieName="userAgreementCookies"
          declineButtonText="I decline"
          style={{ background: "#2B373B", zIndex: 2000 }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={30}
        >
          We use cookies! Bazarma collects data through cookies to deliver our
          service and improve the product.
        </CookieConsent>
      </Suspense>
      <Helmet title=" بازارما - نیازمندی خرید و فروش - خدمات - اجاره خانه در سوئد - Bazarma">
        <meta
          name="description"
          content="بازارما Bazarma خرید و فروش - خدمات - اجاره خانه در سوئد "
        />
        <link rel="canonical" href="https://www.bazarma.se/" />
        <meta property="og:url" content="https://www.bazarma.se" />
        <meta property="og:type" content="website" />
      </Helmet>
      <TopContainerWrapper>
        <TopContainer />
      </TopContainerWrapper>
      <AppWrapper>
        <AppRoutes dispatch={dispatch} />
      </AppWrapper>
      <BottomBar />
    </div>
  );
}

export default App;
