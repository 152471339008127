import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import "firebase/database";
import Axios from "axios";
import ApiStatus from "../Farsi/Shared/Constants/apiStatus";

const initialState = {
  selectedCategory: "",
  selectedCity: "",
  searchInputText: "",
  allAdvertisments: [],
  selectedAd: {},
  apiStatus: {
    fetchingAds: ApiStatus.ApiIdle,
    fetchingSelectedAd: ApiStatus.ApiIdle,
  },
};

export const fetchSelectedAdvertisement = createAsyncThunk(
  "bazar/fetchSelectedAdvertisement",
  async (id) => {
    try {
      const res = await Axios.get(
        `https://app.nordicbazar.com/api/v1/ad/get_ad/${id}`
      );

      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);

export const fetchAdvertisements = createAsyncThunk(
  "bazar/fetchAdvertisements",
  async () => {
    try {
      const res = await Axios.get(
        "https://app.nordicbazar.com/api/v1/ad/list_ads"
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);

const bazarSlice = createSlice({
  name: "bazar",
  initialState,
  reducers: {
    setSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
    },
    setSelectedCity(state, action) {
      state.selectedCity = action.payload;
    },
    setSearchInputText(state, action) {
      state.searchInputText = action.payload;
    },
    setAllAdvertisments(state, action) {
      state.allAdvertisments = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdvertisements.pending, (state) => {
        state.apiStatus.fetchingAds = ApiStatus.ApiStarting;
      })
      .addCase(fetchAdvertisements.fulfilled, (state, action) => {
        state.allAdvertisments = action.payload;
        state.apiStatus.fetchingAds = ApiStatus.ApiDone;
      })
      .addCase(fetchAdvertisements.rejected, (state) => {
        state.apiStatus.fetchingAds = ApiStatus.ApiFailed;
      });
    builder
      .addCase(fetchSelectedAdvertisement.pending, (state) => {
        state.apiStatus.fetchingSelectedAd = ApiStatus.ApiStarting;
      })
      .addCase(fetchSelectedAdvertisement.fulfilled, (state, action) => {
        state.selectedAd = action.payload;
        state.apiStatus.fetchingSelectedAd = ApiStatus.ApiDone;
      })
      .addCase(fetchSelectedAdvertisement.rejected, (state) => {
        state.apiStatus.fetchingSelectedAd = ApiStatus.ApiFailed;
      });
  },
});

export const {
  setSelectedCategory,
  setSelectedCity,
  setSearchInputText,
  setAllAdvertisments,
} = bazarSlice.actions;

export default bazarSlice.reducer;
