import styled from "styled-components";
import { colors } from "../../tokens/colors";

export const Button = styled.button`
  background-color: ${(props) =>
    props?.type !== "Filled" ? colors.white : colors.darkBlue};
  color: ${(props) => (props?.type !== "Filled" ? colors.black : colors.white)};
  border: ${(props) =>
    props?.type !== "Filled" ? "1px solid colors.black" : "none"};
  padding: 12px 36px;
  min-width: 116px;
  font-size: 1rem;
  border-radius: 50px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-weight: 700;

  margin-top: ${(props) => props.mt || "0"};
  margin-right: ${(props) => props.mr || "0"};
  margin-bottom: ${(props) => props.mb || "0"};
  margin-left: ${(props) => props.ml || "0"};

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  &:hover {
    background-color: ${(props) =>
      props?.type !== "Filled" ? colors.white : colors.darkBlue};
    transform: ${(props) => (props.disabled ? "none" : "translateY(-2px)")};
  }

  &:active {
    background-color: ${(props) =>
      props.disabled ? colors.darkBlue : colors.lightGray};
    transform: ${(props) => (props.disabled ? "none" : "translateY(0)")};
  }
`;

export const SecondaryButton = styled.button`
  background-color: ${(props) =>
    props?.type !== "Filled" ? colors.white : colors.darkBlue};
  color: ${(props) => (props?.type !== "Filled" ? colors.black : colors.white)};
  border: ${(props) =>
    props?.type !== "Filled" ? "1px solid colors.black" : "none"};
  padding: ${({ padding }) => padding || "7px 21px"};
  min-width: 105px;
  font-size: ${({ fontSize }) => fontSize || "17px"};
  border-radius: 50px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-weight: 700;
  margin-top: ${({ mt }) => mt || "0"};
  margin-bottom: ${({ mb }) => mb || "0"};
  margin-left: ${({ ml }) => ml || "0"};
  margin-right: ${({ mr }) => mr || "0"};
  &:hover {
    background-color: ${(props) =>
      props?.type !== "Filled" ? colors.white : colors.darkBlue};
    transform: translateY(-2px);
    cursor: pointer;
  }

  &:active {
    background-color: ${colors.lightBlue};
    transform: translateY(0);
  }
`;

export const OrderButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  padding: 5px 30px;
  border-radius: 15px;
  height: 40px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  margin: 0 0.5rem;
  width: 150px;
  border: none;
  cursor: pointer;

  &:hover {
    transform: scale(0.95);
  }

  @media (max-width: 768px) {
    height: 30px;
    padding: 4px 10px;
  }
`;

export const SubmitButton = styled.input`
  width: 100%;
  height: 2.5rem;
  margin-top: 1rem;
  font-size: 1.2rem;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${colors.darkBlue};
  }
`;

export const ModalCloseButton = styled.img`
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 1.3rem;
  height: auto;
  cursor: pointer;

  &:hover {
    transform: scale(0.9);
  }
`;
export const CircleButton = styled.button`
  background-color: ${({ type }) =>
    type === "Filled" ? colors.darkBlue : colors.white};
  color: ${({ type }) => (type === "Filled" ? colors.white : colors.black)};
  border: ${({ type }) =>
    type === "Filled" ? `2px solid ${colors.darkGray}` : "none"};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: ${colors.gray};
    transform: translateY(-2px);
  }

  &:active {
    background-color: ${colors.darkGray};
    transform: translateY(0);
  }
`;
