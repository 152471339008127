export const normalizePhoneNumber = (number) => {
  let cleanedNumber = number.replace(/[^+\d]/g, "");

  if (cleanedNumber.startsWith("00")) {
    cleanedNumber = "+" + cleanedNumber.slice(2);
  } else if (cleanedNumber.startsWith("0")) {
    cleanedNumber = "+46" + cleanedNumber.slice(1);
  } else if (!cleanedNumber.startsWith("+")) {
    cleanedNumber = "+46" + cleanedNumber;
  }
  cleanedNumber = cleanedNumber.replace(/^\++/, "+");

  cleanedNumber = cleanedNumber.replace(
    /(\+\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
    "$1$2$3$4$5"
  );

  return cleanedNumber;
};
