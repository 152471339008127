import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import Cookies from "js-cookie";
import { storage } from "../firebase";
import ApiStatus from "../Farsi/Shared/Constants/apiStatus";

const initialState = {
  rentingPart: "",
  registerHomeStep: 1,
  address: {
    lng: "",
    lat: "",
    houseNumber: "",
    street: "",
    postalCode: "",
    county: "",
    city: "",
    area: "",
  },
  formattedAddress: "",

  interior: "",
  rentHomeType: "",
  size: "",
  rentStartDate: "asap",
  rentEndDate: "ufn", //until further notice
  tenant: "",
  county: "",
  telephone: "",
  email: "",
  rooms: "",
  pets: false,
  smoking: false,
  images: [],
  mainImage: "",
  price: "",
  deposit: "",
  downloadedImagesLinks: [],
  downloadedMainImageLink: "",
  description: "",
  id: 0,
  isAdvertisementSent: false,

  apiStatus: {
    createHomeAd: ApiStatus.ApiIdle,
    editHomeAd: ApiStatus.ApiIdle,
    uploadImages: ApiStatus.ApiIdle,
    geoCodeApiAddress: ApiStatus.ApiIdle,
  },
};

export const uploadHomeImages = createAsyncThunk(
  "registerHome/uploadHomeImages",
  async (_, { getState, dispatch }) => {
    const state = getState().registeringHome;
    const images = state.images;
    const dateObj = new Date();
    const newdate = `${dateObj.getUTCFullYear()}-${
      dateObj.getUTCMonth() + 1
    }-${dateObj.getUTCDate()}-${dateObj.getUTCHours()}-${dateObj.getUTCMinutes()}`;

    let galleryUrlLinks = [];

    const fileObjectsImages = images.filter((item) => item instanceof File);
    const stringObjectsImages = images.filter(
      (item) => typeof item === "string"
    );

    // Push strings directly to galleryUrlLinks
    galleryUrlLinks.push(...stringObjectsImages);

    // Upload files and get download URLs
    if (fileObjectsImages.length > 0) {
      const galleryImgUpload = await Promise.all(
        fileObjectsImages.map(async (pic) => {
          const filename = pic.name;
          const storageRef = storage.ref(`${newdate}/${filename}`);
          await storageRef.put(pic);
          return await storageRef.getDownloadURL();
        })
      );
      galleryUrlLinks.push(...galleryImgUpload);
    }
    dispatch(setHomeDownloadedImages(galleryUrlLinks));
    dispatch(setHomeDownloadedMainImage(galleryUrlLinks[0]));
    return {
      galleryUrlLinks,
    };
  }
);

export const geoCodeApiAddress = createAsyncThunk(
  "geoCodeApiAddress",
  async ({ lat, lng }) => {
    try {
      const res = await Axios.post(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBS2uCga-ByNnZcGyTh0kDHJnv1XtJTBnw`
      );

      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
      throw error;
    }
  }
);
export const createHomeAd = createAsyncThunk(
  "createHomeAd",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState().registeringHome;

      const token = Cookies.get("jwt-token");
      const res = await Axios.post(
        "https://app.nordicbazar.com/api/v1/home/create_home",
        {
          renting_part: state.rentingPart,
          address: {
            lat: state.address.lat,
            lng: state.address.lng,
            house_number: state.address.houseNumber,
            street: state.address.street,
            postal_code: state.address.postalCode,
            county: state.address.county,
            city: state.address.city,
            area: state.address.area,
          },
          rooms: state.rooms,
          deposit: state.deposit,
          interior: state.interior,
          rent_home_type: state.rentHomeType,
          size: state.size,
          rent_start_date: state.rentStartDate,
          rent_end_date: state.rentEndDate,
          tenant: state.tenant,
          pets_allowed: state.pets,
          smoking_allowed: state.smoking,
          image_urls: state.downloadedImagesLinks,
          main_image: state.downloadedMainImageLink,
          price: state.price,
          description: state.description,
          telephone: state.telephone,
          email: state.email,
          source: "website",

          consent: {
            necessary: true,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
export const editHomeAd = createAsyncThunk(
  "editHomeAd",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState().registeringHome;

      const token = Cookies.get("jwt-token");
      const res = await Axios.put(
        `https://app.nordicbazar.com/api/v1/home/update_home/${state.id}`,
        {
          renting_part: state.rentingPart,
          address: {
            lat: state.address.lat,
            lng: state.address.lng,
            house_number: state.address.houseNumber,
            street: state.address.street,
            postal_code: state.address.postalCode,
            county: state.address.county,
            city: state.address.city,
            area: state.address.area,
          },
          rooms: state.rooms,
          deposit: state.deposit,
          interior: state.interior,
          rent_home_type: state.rentHomeType,
          size: state.size,
          rent_start_date: state.rentStartDate,
          rent_end_date: state.rentEndDate,
          tenant: state.tenant,
          pets_allowed: state.pets,
          smoking_allowed: state.smoking,
          image_urls: state.downloadedImagesLinks,
          main_image: state.downloadedMainImageLink,
          price: state.price,
          description: state.description,
          telephone: state.telephone,
          email: state.email,
          source: "website",

          consent: {
            necessary: true,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const registerHomeSlice = createSlice({
  name: "registerHome",
  initialState,
  reducers: {
    setHomeRentingPart(state, action) {
      state.rentingPart = action.payload;
    },
    setRegisterHomeStep(state, action) {
      state.registerHomeStep = action.payload;
    },
    setHomeAddress(state, action) {
      state.formattedAddress = action.payload;
    },

    setHomeCity(state, action) {
      state.city = action.payload;
    },
    setHomeInterior(state, action) {
      state.interior = action.payload;
    },
    setHomeSize(state, action) {
      state.size = action.payload;
    },
    setHomeRooms(state, action) {
      state.rooms = action.payload;
    },
    setHomePrice(state, action) {
      state.price = action.payload;
    },
    setHomeDeposit(state, action) {
      state.deposit = action.payload;
    },
    setRentHomeType(state, action) {
      state.rentHomeType = action.payload;
    },
    setRentStartDate(state, action) {
      state.rentStartDate = action.payload;
    },
    setRentEndDate(state, action) {
      state.rentEndDate = action.payload;
    },
    setHomeTelephone(state, action) {
      state.telephone = action.payload;
    },
    setHomeEmail(state, action) {
      state.email = action.payload;
    },
    setUploadHomeImagesIdle(state) {
      state.apiStatus.uploadImages = ApiStatus.ApiIdle;
    },
    setHomeTenant(state, action) {
      state.tenant = action.payload;
    },
    setHomePets(state, action) {
      state.pets = action.payload;
    },
    setHomeSmoking(state, action) {
      state.smoking = action.payload;
    },
    setHomeDetails(state, action) {
      state.description = action.payload;
    },
    setHomeImages(state, action) {
      state.images = action.payload;
    },
    setHomeMainImage(state, action) {
      state.mainImage = action.payload;
    },
    setHomeDownloadedImages(state, action) {
      state.downloadedImagesLinks = action.payload;
    },
    setHomeDownloadedMainImage(state, action) {
      state.downloadedMainImageLink = action.payload;
    },
    setHomeId(state, action) {
      state.id = action.payload;
    },
    setIsAdvertisementSent(state, action) {
      state.isAdvertisementSent = action.payload;
    },
    resetStateRegisteringHomeAd() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createHomeAd.pending, (state) => {
        state.apiStatus.createHomeAd = ApiStatus.ApiStarting;
      })
      .addCase(createHomeAd.fulfilled, (state) => {
        state.apiStatus.createHomeAd = ApiStatus.ApiDone;
      })
      .addCase(createHomeAd.rejected, (state) => {
        state.apiStatus.createHomeAd = ApiStatus.ApiFailed;
      });
    builder
      .addCase(editHomeAd.pending, (state) => {
        state.apiStatus.editHomeAd = ApiStatus.ApiStarting;
      })
      .addCase(editHomeAd.fulfilled, (state) => {
        state.apiStatus.editHomeAd = ApiStatus.ApiDone;
      })
      .addCase(editHomeAd.rejected, (state) => {
        state.apiStatus.createHomeAd = ApiStatus.ApiFailed;
      });

    builder
      .addCase(uploadHomeImages.pending, (state) => {
        state.apiStatus.uploadImages = ApiStatus.ApiStarting;
      })
      .addCase(uploadHomeImages.fulfilled, (state) => {
        state.apiStatus.uploadImages = ApiStatus.ApiDone;
      })
      .addCase(uploadHomeImages.rejected, (state) => {
        state.apiStatus.uploadImages = ApiStatus.ApiFailed;
      });
    builder
      .addCase(geoCodeApiAddress.pending, (state) => {
        state.apiStatus.geoCodeApiAddress = ApiStatus.ApiStarting;
      })
      .addCase(geoCodeApiAddress.fulfilled, (state, action) => {
        state.apiStatus.geoCodeApiAddress = ApiStatus.ApiDone;

        const results = action.payload.results.slice(0, 6); // Consider the first 4 results
        let houseNumber = "";
        let area = "";
        let street = "";
        let postalCode = "";
        let city = "";
        let county = "";
        let lat = null;
        let lng = null;

        for (const result of results) {
          const components = result.address_components;
          const geometry = result.geometry;

          if (!houseNumber) {
            houseNumber =
              components.find((component) =>
                component.types.includes("street_number")
              )?.long_name || "";
          }

          if (!area) {
            area =
              components.find(
                (component) =>
                  component.types.includes("sublocality") ||
                  component.types.includes("postal_town")
              )?.long_name || "";
          }

          if (!street || !state.formattedAddress.includes(street)) {
            street =
              components.find((component) => component.types.includes("route"))
                ?.long_name || "";
            // If the new street is still not in formattedAddress, reset to empty to continue the loop
            if (!state.formattedAddress.includes(street)) {
              street = "";
            }
          }

          if (!postalCode) {
            postalCode =
              components.find((component) =>
                component.types.includes("postal_code")
              )?.long_name || "";
          }

          if (!city) {
            city =
              components.find((component) =>
                component.types.includes("postal_town")
              )?.long_name || "";
          }

          if (!county) {
            county =
              components.find((component) =>
                component.types.includes("administrative_area_level_1")
              )?.long_name || "";
          }

          if (!lat && !lng) {
            lat = geometry.location.lat;
            lng = geometry.location.lng;
          }

          // Break the loop if all variables are found
          if (
            houseNumber &&
            area &&
            street &&
            postalCode &&
            city &&
            county &&
            lat &&
            lng
          ) {
            break;
          }
        }

        state.address = {
          houseNumber,
          area,
          street: state.formattedAddress.includes(street) ? street : "",
          postalCode,
          city,
          county,
          lat,
          lng,
        };
      })
      .addCase(geoCodeApiAddress.rejected, (state) => {
        state.apiStatus.geoCodeApiAddress = ApiStatus.ApiFailed;
      });
  },
});

export const {
  setRentHomeType,
  setHomeRentingPart,
  setRegisterHomeStep,
  setHomeCity,
  setHomeAddress,
  setHomeInterior,
  setHomeSize,
  setHomeRooms,
  setHomeDeposit,
  setRentStartDate,
  setRentEndDate,
  setHomeTenant,
  setHomeEmail,
  setHomeTelephone,
  setHomePets,
  setHomeSmoking,
  setHomeDetails,
  setUploadHomeImagesIdle,
  setHomeImages,
  setHomePrice,
  setHomeMainImage,
  setHomeDownloadedImages,
  setHomeDownloadedMainImage,
  setHomeId,
  setIsAdvertisementSent,
  resetStateRegisteringHomeAd,
} = registerHomeSlice.actions;

export default registerHomeSlice.reducer;
