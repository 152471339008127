import { createGlobalStyle } from "styled-components";
import { fonts } from "../tokens/fonts";
import { colors } from "../tokens/colors";

export const GlobalStyle = createGlobalStyle`
  ${fonts.fontFaces}

  * {
    box-sizing: border-box;
     font-family: ${fonts.primary};
    margin: 0;
    padding: 0;
    overscroll-behavior: none;
  }


  a {
    text-decoration: none;
    color: ${colors.black};
    outline: none;
  }

  .App {
    text-align: center;
  }

  .activeClass {
    border-bottom: 1px solid ${colors.darkBlue};
  }

  .activeCat {
    background-color: ${colors.darkBlue};
    color: ${colors.white};
  }

  .btn:disabled {
    background-color: #373a47;
  }
`;

export default GlobalStyle;
