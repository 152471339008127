import styled from 'styled-components';
import { typography } from '../../tokens/typography';
import { colors } from '../../tokens/colors';

export const Header = styled.h1`
  font-family: ${typography.headerFont};
  font-size: ${typography.headerSize};
  color: ${colors.darkBlue};
  margin-bottom: 20px;
`;

export const SubHeader = styled.h2`
  font-family: ${typography.headerFont};
  font-size: ${typography.subheaderSize};
  color: ${colors.darkBlue};
  margin-bottom: 16px;
`;
