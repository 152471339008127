import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import "firebase/database";
import Axios from "axios";
import ApiStatus from "../Farsi/Shared/Constants/apiStatus";

const initialState = {
  selectedCategory: "",
  selectedCity: "",
  searchInputText: "",
  allHomes: [],
  apiStatus: {
    fetchingAllHomes: ApiStatus.ApiIdle,
    fetchingSelectedHome: ApiStatus.ApiIdle,
  },
  selectedHome: {},
};

export const fetchSelectedHome = createAsyncThunk(
  "business/fetchSelectedHome",
  async (id) => {
    try {
      const res = await Axios.get(
        `https://app.nordicbazar.com/api/v1/home/get_home/${id}`
      );

      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);

export const fetchAllHomes = createAsyncThunk(
  "business/fetchAllHomes",
  async () => {
    try {
      const res = await Axios.get(
        "https://app.nordicbazar.com/api/v1/home/list_homes"
      );
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
    },
    setSelectedCity(state, action) {
      state.selectedCity = action.payload;
    },
    setSearchInputText(state, action) {
      state.searchInputText = action.payload;
    },
    setAllAdvertisments(state, action) {
      state.allHomes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllHomes.pending, (state) => {
        state.apiStatus.fetchingAllHomes = ApiStatus.ApiStarting;
      })
      .addCase(fetchAllHomes.fulfilled, (state, action) => {
        state.allHomes = action.payload;
        state.apiStatus.fetchingAllHomes = ApiStatus.ApiDone;
      })
      .addCase(fetchAllHomes.rejected, (state, action) => {
        state.error = action.payload;
        state.apiStatus.fetchingAllHomes = ApiStatus.ApiFailed;
      });
    builder
      .addCase(fetchSelectedHome.pending, (state) => {
        state.apiStatus.fetchingSelectedHome = ApiStatus.ApiStarting;
      })
      .addCase(fetchSelectedHome.fulfilled, (state, action) => {
        state.selectedHome = action.payload;
        state.apiStatus.fetchingSelectedHome = ApiStatus.ApiDone;
      })
      .addCase(fetchSelectedHome.rejected, (state, action) => {
        state.error = action.payload;
        state.apiStatus.fetchingSelectedHome = ApiStatus.ApiFailed;
      });
  },
});

export const {
  setSelectedCategory,
  setSelectedCity,
  setSearchInputText,
  setAllAdvertisments,
} = homeSlice.actions;

export default homeSlice.reducer;
