import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { colors } from "./design-system";

const BtnContainer = styled.div`
  background-color: ${colors.darkBlue};
  padding: 5px;
  width: 8rem;
  @media (max-width: 768px) and (min-width: 300px) {
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) and (min-width: 300px) {
  }
`;
const Txt = styled.span`
  color: #fff;
  @media (max-width: 768px) and (min-width: 300px) {
  }
`;
export class noMatchPage extends Component {
  render() {
    return (
      <Container>
        <h2>صفحه ای یافت نشد</h2>
        <NavLink to="/">
          <BtnContainer>
            <Txt>صفحه نخست</Txt>
          </BtnContainer>
        </NavLink>
      </Container>
    );
  }
}

export default noMatchPage;
